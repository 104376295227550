import React from "react"
import Layout from "../components/Layout/Layout"
import SEO from "../components/Seo/Seo"
import PageHeader from '../components/Layout/PageHeader/PageHeader'
import SectionPageMargin from '../components/Layout/Section/SectionPageMargin'
import Heading2 from '../components/Type/Heading2'
import Paragraph1 from '../components/Type/Paragraph1'

const CleaningPage = ({ location }) =>
(
  <Layout>

    <SEO title="Cleaning and detailing"
      description="Overview of Summon's cleaning and detailing service"
      location={location}
    />

    <PageHeader title="Cleaning and detailing" />

    <SectionPageMargin>
      <div>
        <Heading2>
          Our cleaning and detailing service
        </Heading2>

        <Paragraph1>
          We will prepare the interior and exterior of your car in the following manner:
        </Paragraph1>

        <ul>
          <li>
            Paint decontamination and quick detail - we will clean dirt and foreign manner from your paint and exterior
            surfaces, by hand, including the use of a clay bar if required (ask us for more details on this if you are
            uncertain as to what this means).   This is the lowest impact method of taking your car's paint back to a factory
            showroom state prior to application of a protective layer.  This ensures your paintwork will look fantastic in photography.
          </li>
          <li>
            Protective layer - we will either use a liquid protective solution or hand applied wax (for cars with immaculate paint
            that has not required a clay bar, this will typically be a wipe on QD/show solution...for cars that have required a clay
            bar this stage may involve a coating of hand applied wax).
          </li>
          <li>
            Interior - we will vaccuum the carpets and clean all touch points of the vehicle, including seats.  We may use a leather
            cleaner and nourisher on leather surfaces such as seats, steering wheel and door handles.
          </li>
        </ul>

        <Paragraph1>
          Our aim - to clean and prepare your car through the most sympathetic and least aggressive methods available, to ensure no
          degradation of your paint or exterior surfaces.  It is possible to clean quickly using aggressive chemicals however this
          inevitably has some impact on the long term performance and visual state of the vehicle surface as well as the potential
          for unintended side effects, such as faded trim and badge items.
        </Paragraph1>

        <Paragraph1>
          What we don't do;
        </Paragraph1>

        <ul>
          <li>Use power tools to facilitate a 'paint correction' of your vehicle.</li>
          <li>Resurface and refinish exterior vinyl/plastic components (ie machine polishing headlight lenses, badging, chrome work)/</li>
        </ul>

        <Heading2>
          Cleaning FAQ
        </Heading2>

        <Paragraph1>
          <strong>What is a 'detail'</strong> - the word 'detail' is used in a variety of contexts to mean different things.  For those working in the
          professional detailing space, who would typically take a client car for a day or two at minimum, an entry level detail would
          include several passes of paint decontamination, some level of power tool based paint correction, removal of wheels, taping
          and refinishing of badges and trim items.  The paint would then be coated with either ppf or a ceramic coating or several
          layers of wax (all with the aim of providing paint protection for 6 - 24 months).  The commercial price of a detail would
          usually range from $1200 to $10000 depending on the level of work undertaken and the initial state of the car.  Some cleaning
          services offer a 'detail' that can be undertaken in 2 - 3 hours.  While using the word 'detail', this would typically be a
          clean followed by a minor level of paint protection.
        </Paragraph1>

        <Paragraph1>
          <strong>What is a QD/Show solution</strong> - these products are wipe on solutions, designed to produce a glossy and lustrous
          finish on a vehicle's paint.  They are designed to look their best for 24 hours and typically used to provide both protection
          to the paint and 'show' the vehicle to viewers at car shows etc.
        </Paragraph1>

        <Paragraph1>
          <strong>What is a Clay Bar </strong> - a sticky type of bar, a little like playdo in appearance.  A clay bar is rubbed over paint, with a lubricant,
          to lift surface contaminants out of the paint, that would not come out by other means (pressure hose, hand sponge etc).  A vehicle
          that has spent its life outdoors or parked under a tree will typically benefit from a clay bar.
        </Paragraph1>

        <Paragraph1>
          <strong>What is a Sympathetic clean </strong> - A method of cleaning that ensures little to no impact on the surface you are cleaning.  An abrasive cloth and harsh
          chemical cleaner will clean any surface...however this will likely also harm the surface (in a vehicle context, cause scratches or degradation to paint
          and material).  A 'one pass' wipe method will clean paintwork in a very soft manner but may not remove stubborn stains or chemical buildup on the paint.
          Such stains and contaminants are best removed via a clay bar, which is also a very gentle method of cleaning a paint surface.
        </Paragraph1>

      </div>
    </SectionPageMargin>
  </Layout>
)

export default CleaningPage

